// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Font defs **/ 

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/SourceSansPro/SourceSansProLight.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/SourceSansPro/SourceSansProRegular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../assets/fonts/SourceSansPro/SourceSansProSemiBold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/SourceSansPro/SourceSansProBold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/SourceSansPro/SourceSansProRegularItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #4677ee;
  --ion-color-primary-rgb: 70,119,238;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3e69d1;
  --ion-color-primary-tint: #5985f0;

  --ion-color-secondary: #82a0f6;
  --ion-color-secondary-rgb: 130,160,246;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #728dd8;
  --ion-color-secondary-tint: #8faaf7;

  --ion-color-tertiary: #d1ddfb;
  --ion-color-tertiary-rgb: 209,221,251;
  --ion-color-tertiary-contrast: #696969;
  --ion-color-tertiary-contrast-rgb: 105,105,105;
  --ion-color-tertiary-shade: #b8c2dd;
  --ion-color-tertiary-tint: #d6e0fb;

  --ion-color-success: #009344;
  --ion-color-success-rgb: 0,147,68;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #00813c;
  --ion-color-success-tint: #1a9e57;

  --ion-color-warning: #fec10e;
  --ion-color-warning-rgb: 254,193,14;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0aa0c;
  --ion-color-warning-tint: #fec726;

  --ion-color-danger: #ec1d24;
  --ion-color-danger-rgb: 236,29,36;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d01a20;
  --ion-color-danger-tint: #ee343a;

  --ion-color-dark: #020303;
  --ion-color-dark-rgb: 2,3,3;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #020303;
  --ion-color-dark-tint: #1b1c1c;

  --ion-color-medium: #a6a8ab;
  --ion-color-medium-rgb: 166,168,171;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #929496;
  --ion-color-medium-tint: #afb1b3;

  --ion-color-light: #efefef;
  --ion-color-light-rgb: 239,239,239;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d2d2d2;
  --ion-color-light-tint: #f1f1f1;
/*
  --ion-color-brand0: #f0f2fd;
  --ion-color-brand0-rgb: 240,242,253;
  --ion-color-brand0-contrast: #4a4a4a;
  --ion-color-brand0-contrast-rgb: 74,74,74;
  --ion-color-brand0-shade: #d3d5df;
  --ion-color-brand0-tint: #f2f3fd;

  --ion-color-brand1: #f3f7ff;
  --ion-color-brand1-rgb: 243,247,255;
  --ion-color-brand1-contrast: #4a4a4a;
  --ion-color-brand1-contrast-rgb: 74,74,74;
  --ion-color-brand1-shade: #d6d9e0;
  --ion-color-brand1-tint: #f4f8ff;

  --ion-color-brand2: #31b8d5;
  --ion-color-brand2-rgb: 49,184,213;
  --ion-color-brand2-contrast: #000000;
  --ion-color-brand2-contrast-rgb: 0,0,0;
  --ion-color-brand2-shade: #2ba2bb;
  --ion-color-brand2-tint: #46bfd9;
*/
  --ion-color-brand0: #4677ee;
  --ion-color-brand0-rgb: 70,119,238;
  --ion-color-brand0-contrast: #ffffff;
  --ion-color-brand0-contrast-rgb: 255,255,255;
  --ion-color-brand0-shade: #3e69d1;
  --ion-color-brand0-tint: #5985f0;

  --ion-color-brand1: #4677ee;
  --ion-color-brand1-rgb: 70,119,238;
  --ion-color-brand1-contrast: #ffffff;
  --ion-color-brand1-contrast-rgb: 255,255,255;
  --ion-color-brand1-shade: #3e69d1;
  --ion-color-brand1-tint: #5985f0;

  --ion-color-brand2: #4677ee;
  --ion-color-brand2-rgb: 70,119,238;
  --ion-color-brand2-contrast: #ffffff;
  --ion-color-brand2-contrast-rgb: 255,255,255;
  --ion-color-brand2-shade: #3e69d1;
  --ion-color-brand2-tint: #5985f0;

  --ion-color-brand3: #4677ee;
  --ion-color-brand3-rgb: 70,119,238;
  --ion-color-brand3-contrast: #ffffff;
  --ion-color-brand3-contrast-rgb: 255,255,255;
  --ion-color-brand3-shade: #3e69d1;
  --ion-color-brand3-tint: #5985f0;

.ion-color-brand0 {
  --ion-color-base: var(--ion-color-brand0) !important;
  --ion-color-base-rgb: var(--ion-color-brand0-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand0-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand0-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand0-shade) !important;
  --ion-color-tint: var(--ion-color-brand0-tint) !important;
}

.ion-color-brand1 {
  --ion-color-base: var(--ion-color-brand1) !important;
  --ion-color-base-rgb: var(--ion-color-brand1-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand1-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand1-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand1-shade) !important;
  --ion-color-tint: var(--ion-color-brand1-tint) !important;
}

.ion-color-brand2 {
  --ion-color-base: var(--ion-color-brand2) !important;
  --ion-color-base-rgb: var(--ion-color-brand2-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand2-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand2-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand2-shade) !important;
  --ion-color-tint: var(--ion-color-brand2-tint) !important;
}

.ion-color-brand3 {
  --ion-color-base: var(--ion-color-brand3) !important;
  --ion-color-base-rgb: var(--ion-color-brand3-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand3-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand3-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand3-shade) !important;
  --ion-color-tint: var(--ion-color-brand3-tint) !important;
}

/** OHN CSS Variables **/

  --ion-font-family: "Source Sans Pro", sans-serif;
  --ion-font-family-head:  "Source Sans Pro", sans-serif;

  --ion-text-color: #4a4a4a;
  --ion-text-color-rgb: 74,74,74;
  --ion-text-color-contrast: #ffffff;
  --ion-text-color-contrast-rgb: 255,255,255;
  --ion-text-color-shade: #414141;
  --ion-text-color-tint: #5c5c5c;

  --ohn-logo-width: 4rem;
  --ohn-seal-width: 75vw;
}